.search-form .ant-space-item:nth-child(1) {
    width: 30%;
    display: block;
  }
  
  .search-form .ant-space-item:nth-child(2) {
    width: 25%;
    display: block;
  }
  
  .search-form .ant-space-item:nth-child(3) {
    width: 25%;
    display: block;
  }
  
  .search-form .ant-space-item:nth-child(4) {
    width: 20%;
    display: block;
  }