a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}

.places-container {
  justify-content: center;
/* display: flex; */
position: relative;
  max-width: 100%;
  top: 0rem;
  margin-bottom: 10rem;
  height: 20rem;
    overflow:hidden;
    margin-left: -5rem;
}

.client-places-container{

  transform: translateX(-8%);
position: relative;
  max-width: 85rem;
  top: 0rem;
  margin-bottom: 10rem;
  height: 20rem;
    overflow:hidden;
}


.map-container {
  overflow:hidden;
  width: 50rem;
  height: 22rem;
  left: 10rem;
  z-index: 1;
}


.map-container-task{

overflow:hidden;
    width: 100%;
  height: 22rem;
  left: 10rem;
  z-index: 1;


}
.combobox-input {
  width: 49.5rem;
  padding: 0.5rem;

  top: 0rem;
  margin-left: 10rem;
  z-index: 200;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 35px;
  position: relative;
}
.combobox-input:focus {
  outline: none !important;
  border: 1px solid;
  border-color: #fc9c3c;
  box-shadow: 0 0 2px#fba958;
}

.ComboboxPopover {
  border-color: #fba958;
  position: fixed;
  top: 2.1rem;
  margin-left: 10rem;
  width: 55rem;
  z-index: 250 !important;
}
.combobox-input-search {
width: 100%;
  padding: 0.5rem;
 top: 0rem;
  z-index: 200;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 35px;
  position: relative;
}
.combobox-search .ant-space-item{
  width: 100% !important;
}
.combobox-input-search:focus {
  outline: none !important;
  border: 1px solid;
  border-color: #fc9c3c;
  box-shadow: 0 0 2px#fba958;
}

.ComboboxPopover-search {
  border-color: #fba958;
  border-top: 0;
  position: fixed;
  top:0;
  width: 55rem;
  z-index: 250 !important;
}
.ClientLocationComboboxPopover {
   border-color: #fba958;
  position: fixed;
  top: 2rem;
  margin-left: 10rem;
  width: 49.5rem;
  z-index: 250 !important;
}




.submitButton {
  margin-left: 51rem;
}

.submitButtonTask {

  float: right;
  margin-right: 6rem;
}

