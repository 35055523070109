.loginContainer{


background-color: rgb(255, 255, 255);
justify-content: center;
align-items: center;
justify-items: center;
width: 30rem;
height: 42rem;
margin: 5rem auto;
border-radius: 10px;
box-shadow: 0px 6px 15px -3px rgba(130, 130, 130, 0.53);
}

.changePasswordContainer{


background-color: rgb(255, 255, 255);
justify-content: center;
align-items: center;
justify-items: center;
width: 30rem;
height: 42rem;
margin: 5rem auto;
border-radius: 10px;
box-shadow: 0px 6px 15px -3px rgba(130, 130, 130, 0.53);
}

.loginForm{

    margin-left: 5rem !important ;
    margin-top: 5rem;
    padding-top: 0rem;
}

body{

background-color:'white';

}